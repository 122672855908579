import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import CommonDataServices from '../services/CommonDataServices'
import Loader from './Loader';

export default class NavMenu extends React.PureComponent {
    private defaultMenu: string = "Home"
    accessibleMenu: any = {};

    state = {
        isOpen: false,
        activeMenuName: window.location.pathname == "/" ? this.defaultMenu : window.location.pathname.replace("/", ""),
        header: "",
        loading: true,
    };

    services = new CommonDataServices();

    private handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    }

    async componentDidMount() {
        try {
            const response = await this.services.getAccessibleMenu();
            this.accessibleMenu.reportGenie = response.reportGenie;
        } catch (e) {
            console.log(e);
        }
        finally {
            this.setState({ loading: false })
        }
    }

    private menu = (linkName: string, menuText: string) => {
        return <NavItem onClick={() => this.setState({ activeMenuName: linkName })}>
            <NavLink tag={Link} className={`text-dark ${linkName == this.state.activeMenuName ? "active" : ""}`}
                to={`/${linkName == this.defaultMenu ? "" : linkName}`}>{menuText}
            </NavLink>
        </NavItem >
    }

    public render() {
        if (this.state.loading)
            return <Loader isActive={true} />

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <div className="container-fluid">
                        <a href="#">
                            <img src="/Orglogo.svg" className={`org-img`} /></a>
                        <NavbarBrand tag={Link} to="/">Raiser Web Portal</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow ">
                                {this.menu("Home", "Configuration Management")}
                                {this.accessibleMenu.reportGenie && this.menu("ExpressionMapping", "Report Genie")}
                                <NavItem className="logout" onClick={() => this.handleLogout()}>
                                    <NavLink>Logout <FontAwesomeIcon icon={faSignOutAlt} /></NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </div>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
