import * as React from 'react';
import './styles.scss';
import '../../Utility/gbStyles.scss';
import 'lodash';
import 'bootstrap';
import { OAuth2Provider } from './oauth-pkce';

export default class Login extends React.PureComponent<any, { isRemembered: boolean, userName: string, password: string }> {

    constructor(props: any) {
        super(props);
    }

    private onSignInBtnClick = () => {
        OAuth2Provider();
    }

    public render() {
        return (
            <div className="container-fluid">
                <div className="row login-container" >
                    <div className="col-md-8 px-0">
                        <h1 className="login-app-title">Raiser <br></br>Configuration Management</h1>
                        <div className="side-bg-container">
                        </div>
                    </div>
                    <div className="col-md-4 m-auto px-0">
                        <div className="text-center border border-light p-5">
                            <img src="/favicon.png" />
                            <p className="h4 mb-4">Login</p>
                            <button className="btn  btn-block my-4 shell-btn font-weight-bold"
                                type="submit" onClick={this.onSignInBtnClick}>Sign in</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};


