import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import { Suspense, lazy } from "react";
import './custom.css'
import Loader from './components/Loader';
import Login from './components/Login';
import axios from 'axios';
import { OAuth2Callback } from './components/Login/oauthcallback';
import configuration from './components/ConfigLoader/Config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppSettingLoader from './components/AppSettings';


const config = lazy(() => import("./components/Config/index"));
const expressionMapping = lazy(() => import("./components/ExpressionMapping"));
const expressionBuilder = lazy(() => import("./components/ExpressionBuilder/ExpressionBuilder"));
const configManagement = lazy(() => import("./components/ConfigManagement"));

export default class App extends React.PureComponent<any, any> {

    isUserLoggedIn: boolean = false;

    constructor(props: any) {
        super(props);

        axios.interceptors.request.use(request => {
            if (request.url !== String(configuration.REACT_APP_OAUTH_TOKEN_URL)) {
                // When fetching refresh token we need not check this condition //
                // Before attaching the token check if the token has expired and get fresh token using refresh token //
                const authToken = localStorage.getItem("AuthToken");
                // Attaching token for every api call //
                if (authToken !== null) {
                    request.headers.Authorization = `${authToken}`;
                }
            }
            return request;
        });
    }

    public render() {
        if (!this.isUserLoggedIn)
            OAuth2Callback();

        this.isUserLoggedIn = (sessionStorage.getItem("callback") === "true");

        return (
            <Suspense fallback={<Loader isActive={true} />}>
                {
                    this.isUserLoggedIn ?
                        <AppSettingLoader
                            ready={() =>
                                <Layout>
                                    <Route exact path={["/"]} component={configManagement} />
                                    <Route path='/Config' component={config} />
                                    <Route path='/ExpressionMapping' component={expressionMapping} />
                                    <Route path='/ExpressionBuilder' component={expressionBuilder} />
                                </Layout>}
                            loading={() => <Loader isActive={true} />}>
                        </AppSettingLoader>
                        : <Login path='/Login'></Login>
                }
                <ToastContainer position='top-center' />
            </Suspense>
        );
    }
}

