import { Utilities } from '../Utility/Util';
import { Constants } from '../Utility/Constants'
import { IPatchTable } from '../Models/IPatchTable';
import { IDelete } from '../Models/IDelete';

const util = new Utilities();

export default class CommonDataServices {
    public util = new Utilities();
    baseUrl = `${Constants.hostURL()}/${Constants.controller.common}/`;

    public getAccessibleMenu = async () => {
        return await this.util.getDataFromDB(`${this.baseUrl}GetAccessibleMenu`, {})
    };
}