import configuration from '../ConfigLoader/Config.ts';

export async function OAuth2Provider() {

    // Create and store a random "state" value
    var state = generateRandomString();
    localStorage.setItem("pkce_state", state);

    // Create and store a new PKCE code_verifier (the plaintext random secret)
    var code_verifier = generateRandomString();
    localStorage.setItem("pkce_code_verifier", code_verifier);

    // Hash and base64-urlencode the secret to use as the challenge
    var code_challenge = await pkceChallengeFromVerifier(code_verifier);

    // Build the authorization URL
    var url = configuration.REACT_APP_OAUTH_AUTHORIZATION_URL
        + "?response_type=code"
        + "&client_id=" + encodeURIComponent(configuration.REACT_APP_OAUTH_CLIENTID)
        + "&state=" + encodeURIComponent(state)
        + "&scope=" + encodeURIComponent("openid")
        + "&redirect_uri=" + encodeURIComponent(configuration.REACT_APP_OAUTH_REDIRECTURI)
        + "&code_challenge=" + encodeURIComponent(code_challenge)
        + "&code_challenge_method=S256"
        ;

    // Redirect to the authorization server
    window.location = url;

    //////////////////////////////////////////////////////////////////////
    // PKCE HELPER FUNCTIONS

    // Generate a secure random string using the browser crypto functions
    function generateRandomString() {
        var array = new Uint32Array(28);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    }

    // Calculate the SHA256 hash of the input text. 
    // Returns a promise that resolves to an ArrayBuffer
    function sha256(plain) {
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest('SHA-256', data);
    }

    // Base64-urlencodes the input string
    function base64urlencode(str) {
        // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
        // btoa accepts chars only within ascii 0-255 and base64 encodes them.
        // Then convert the base64 encoded to base64url encoded
        //   (replace + with -, replace / with _, trim trailing =)
        return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
            .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    // Return the base64-urlencoded sha256 hash for the PKCE challenge
    async function pkceChallengeFromVerifier(v) {
        var hashed = await sha256(v);
        return base64urlencode(hashed);
    }
}