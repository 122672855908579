import { Utilities } from "../../Utility/Util";

const appsettings = {} as any;
export default appsettings;

async function load(util: Utilities) {
    try {
        const response = await util.getTableData('app', 'appsettings');
        if (response != null && response.data != null) {
            for (let prop in appsettings) {
                if (appsettings.hasOwnProperty(prop)) {
                    delete appsettings[prop]
                }
            }
            response.data.forEach((a: any) => {
                appsettings[a.key] = a.value;
            })
            console.log('Appsettins loaded.');
        }
    } catch (e) {
        console.log(e);
    }
}
export { load }