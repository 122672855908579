import * as React from 'react';
import NavMenu from './NavMenu';

interface ILayoutProps {
  children?: React.ReactNode
}

const Layout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <React.Fragment>
      <NavMenu />
      {children}
    </React.Fragment>
  );
};

export default Layout;
