import configuration from '../components/ConfigLoader/Config'

export const Constants: any = {
    hostURL: () => configuration.REACT_APP_API_URL,

    controller: {
        dynamicData: "DynamicData",
        config: "Config",
        common: "Common",
        expressionMapping: "ExpressionMapping"
    },
    actions: {
        getTables: "GetTables",
        getTableData: "GetTableData",
        getTableSchema: "GetTableSchema",
        getTableConfig: "GetTableConfig",
        patchItems: "PatchItems",
        deleteItemFromDB: "DeleteItemFromDB",
        LoadJSONDataToTable: "LoadJSONDataToTable",
        getAllSchemasTables: "GetAllSchemasTables",
        insertItemInConifg: "InsertItemInConifg",
        updateItemInConifg: "UpdateItemInConifg",
        deleteItemFromConifg: "DeleteItemFromConifg",
        getRecordHistory: "GetRecordHistory",
        isUserValid: "isUserValid",
        runJob: "RunJob",
        getExpressionMappingBaseData: "GetExpressionMappingBaseData",
        getExpressionMappings: "GetExpressionMappings",
        getImportHistoryData: "GetImportHistoryData",
        runDynamicApi: "RunDynamicApi"
    },
    auditColumns: {
        CreateBy: "meta_creator_nm",
        Created: "meta_created_dttm",
        ModifiedBy: "meta_changed_by_nm",
        Modified: "meta_changed_dttm"
    },
    rmaTables: {
        quotedate: "RMA_QUOTEDATE"
    },
    rmaAdjustmentReadOnlyColumns: {
        QuoteDate: "quote_date",
        BatchNumber: "batch_num"
    },
    configTable: {
        name: "config"
    },
    defaultValue: {
        table: "Select Configuration Table Name to View / Edit",
        noDataToDisplay: "No data to display",
        user: "SYNAPSE_UI_USER"
    },
    notebook_run_status: {
        completed: "completed",
        failed: "failed"
    },
    bgColor: {
        newItem: '#cce5ff',
        modifiedItem: 'rgba(23,162,184,0.2)'
    },
    border: {
        gridCell: '.0625rem solid #e8e8e8',
    },
    orderBy: {
        asc: "ASC",
        desc: "DESC"
    },
    frameWorkComponent: {
        select2: "Select2CellEditor",
        datePicker: "DatePickerCellEditor"
    }
}


