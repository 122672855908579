const configuration = {} as any;
export default configuration;

function load() {
    return fetch('config.json')
        .then(result => result.json())
        .then((newconfig) => {
            for (let prop in configuration) {
                if (configuration.hasOwnProperty(prop)) {
                    delete configuration[prop]
                }
            }
            for (let prop in newconfig) {
                configuration[prop] = newconfig[prop]
            }
            return configuration;
        });
}
export { load }